import React, { useContext, useEffect, useRef, useState } from 'react'
import { IChatActionBar, IChatActionBarFormValues, IChatContext } from '../../resources/interfaces/chat.interface';
import { Controller, useForm } from 'react-hook-form';
import FormTextInput from '../form-elements/textInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSocket } from '../../resources/hooks/socketHook';
import { chatFormValidationSchema } from '../../resources/validators/chat.validator';
import { AZURE_SPEECH_TRANSLATE, SOCKET_EMIT_CHANNEL, SST_THRESHOLD } from '../../resources/constants';
import { ResultReason, AudioConfig, SpeechRecognizer, SpeechConfig, SpeechTranslationConfig, TranslationRecognizer, ServicePropertyChannel } from "microsoft-cognitiveservices-speech-sdk";
import { v4 as uuidv4 } from 'uuid'
import useTimer from '../../resources/hooks/timerHook';
import { ChatContext } from '../../resources/contexts/chat';
import { AppThemeContext } from '../../resources/contexts/appTheme';
import { IAppThemeContext } from '../../resources/interfaces/settings.interface';

const ActionBar: React.FC<IChatActionBar> = ({ onSpeakerOff, language, initiateMic }) => {
  const {
    sessionId,
    threadId,
    isSessionEnded,
    isBotListening,
    isBotTyping,
    addNewMessage,
    setIsTyping,
    setIsBotListening,
    setAudioPlayerPlaying
  } = useContext(ChatContext) as IChatContext
  const socket = useSocket()
  const [micOn, setMicOn] = useState<boolean>(false);
  const timeoutRef = useRef<any>(null);
  const voiceTextRef = useRef<string>("");
  const recognizerRef = useRef<any>();
  const isBotListeningRef = useRef<boolean>(false);
  const isBotTypingRef = useRef<boolean>(false);
  const sessionIdRef = useRef<string | null>(sessionId);
  const { theme, layout } = useContext(AppThemeContext) as IAppThemeContext
  const userMessagesRef = useRef<string[]>([])
  const speechDurationTimerRef = useRef<number>(0)
  const [windowAppId, setWindowAppId] = useState((window as any).bbAppId)
  const {
    control,
    formState: { isValid },
    reset,
    handleSubmit,
  } = useForm<IChatActionBarFormValues>({
    resolver: yupResolver(chatFormValidationSchema),
    defaultValues: {
      messageText: ''
    },
  })
  const timer = useTimer()

  isBotListeningRef.current = isBotListening ?? false
  isBotTypingRef.current = isBotTyping ?? false
  sessionIdRef.current = sessionId

  // use effect for setting initial mic status
  useEffect(() => {
    if (initiateMic) {
      speak()
    } else {
      mute()
    }
  }, [initiateMic])

  // Use effect for stopping recognizer when component unmounts
  useEffect(() => {

    document.addEventListener("visibilitychange", () => {
      let currentMicStatus = sessionStorage.getItem('currentMicStatus') == 'true' ? true : false;
      if (document.hidden) {
        if (currentMicStatus) {
          sessionStorage.setItem('micWillbe', 'true')
          mute();
        }
      } else {
        setMicOn((prev) => {
          let micWillbe = sessionStorage.getItem('micWillbe')
          if (micWillbe == 'true') {
            sessionStorage.removeItem('micWillbe')
            speak();
            return true;
          } else {
            sessionStorage.removeItem('micWillbe')
            return prev
          }
        })
      }
    });


    return () => {
      mute()
    }
  }, []);

  useEffect(() => {
		sessionStorage.setItem('currentMicStatus', micOn?'true':'false');
		if(micOn){
			isBotListeningRef.current = true;
			setIsBotListening(true);
		}
		
	}, [micOn]);


  const mute = async () => {
    setMicOn(false);
    isBotListeningRef.current = false
    setIsBotListening(false)
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
      console.log('reconizer stopped')
    }
  };

  const onTimeout = async () => {
    // Additional actions after 3 seconds of no recognition
    console.log("ontimeout voice text is", voiceTextRef.current, 'is listening = ', isBotListeningRef.current);
    if (
      voiceTextRef.current
      && !voiceTextRef.current.includes('undefined')
      && voiceTextRef.current !== ""
      && isBotListeningRef.current
    ) {
      voiceTextRef.current = voiceTextRef.current.replace(/\.$/, '');
      sendMessage(voiceTextRef.current, 'audio')
      voiceTextRef.current = ""
      userMessagesRef.current = []
      isBotListeningRef.current = false
      setIsBotListening(false)
      console.log('false set from timeout = ', isBotListeningRef.current)
    }

  };

  const resetTimeout = async (timer: number) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      console.log('user is speaking so clear timeout')
    }
    timeoutRef.current = setTimeout(onTimeout, timer);
  };

  const showMessage = (text: string) => {
    userMessagesRef.current.push(text);
    if (windowAppId !== '5dff4b9e-6044-4439-b943-b9cfd2c17849') {
      addNewMessage({
        id: uuidv4(),
        sender: 'user',
        data: {
          bb_type: 'output_text',
          bb_value: text.trim()
        },
        sentAt: new Date(),
      })
    }
    setAudioPlayerPlaying(false)
  }

  const sendMessage = (text: string, source: 'audio' | 'text') => {
    let socketPayload: any = {
      channel: 'websocket',
      thread_id: threadId ?? null,
      session_id: sessionIdRef.current,
      data: userMessagesRef.current.length > 0 ? userMessagesRef.current.join(" ").trim() : text.trim(),
      source: source,
      speech_duration: 0
    }
    if (source === 'audio') {
      socketPayload.speech_duration = speechDurationTimerRef.current
    }
    socket.emit(SOCKET_EMIT_CHANNEL, socketPayload);
    speechDurationTimerRef.current = 0
    reset({ messageText: '' })
    setAudioPlayerPlaying(false)
    timer.startTimer()
    isBotTypingRef.current = true
    setIsTyping(true)
  }

  const speak = async () => {
    isBotListeningRef.current = true
    setIsBotListening(true)
    const utterances = localStorage.getItem('utterances') ? JSON.parse(localStorage.getItem('utterances')!) : []
    // console.log('utterances = ', utterances)
    let recognizer: any = null
    if (language && language.source.startsWith(language.target.substring(0, 5))) {
      console.log('no transalation')
      // Create an instance of a speech configuration with specified subscription key and region
      var noTranslationConfig = SpeechConfig.fromSubscription(AZURE_SPEECH_TRANSLATE, "eastus")
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      // Enable enhanced speech recognition features such as noise suppression
      // noTranslationConfig.enableAudioLogging = true; // Log audio (optional for debugging)
      noTranslationConfig.setServiceProperty("speech.sdk.audio.nearestneighbor", "true", ServicePropertyChannel.UriQueryParameter);
      noTranslationConfig.setServiceProperty("speech.sdk.audio.noisesuppressionlevel", "medium", ServicePropertyChannel.UriQueryParameter);

      if (utterances.length > 0) {
        noTranslationConfig.setServiceProperty("speech.sdk.phrasehint", JSON.stringify({ phrases: utterances }), ServicePropertyChannel.UriQueryParameter);
      }

      noTranslationConfig.speechRecognitionLanguage = language?.source ?? 'en-US';
      recognizer = new SpeechRecognizer(noTranslationConfig, audioConfig);
    } else {
      console.log('using transalation')
      var translationConfig = SpeechTranslationConfig.fromSubscription(AZURE_SPEECH_TRANSLATE, "eastus");
      // console.log('language = ', language.source)
      translationConfig.setServiceProperty("speech.sdk.audio.nearestneighbor", "true", ServicePropertyChannel.UriQueryParameter);
      translationConfig.setServiceProperty("speech.sdk.audio.noisesuppressionlevel", "medium", ServicePropertyChannel.UriQueryParameter);
      if (utterances.length > 0) {
        translationConfig.setServiceProperty("speech.sdk.phrasehint", JSON.stringify({ phrases: utterances }), ServicePropertyChannel.UriQueryParameter);
      }
      translationConfig.speechRecognitionLanguage = language?.source ?? 'en-US';
      translationConfig.addTargetLanguage("en-US"); // quick fix for audio problem
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      recognizer = new TranslationRecognizer(translationConfig, audioConfig);
    }

    setMicOn(true);

    recognizer.recognizing = async (s: any, e: any) => {
      console.log('recognizing typing = ', isBotTypingRef.current)
      if (isBotTypingRef.current) {
        voiceTextRef.current = ""
        userMessagesRef.current = []
        return
      }
      onSpeakerOff && onSpeakerOff()
      isBotListeningRef.current = true
      setIsBotListening(true)
      resetTimeout(SST_THRESHOLD);
    }

    recognizer.recognized = async (s: any, e: any) => {
      console.log('recognized typing = ', isBotTypingRef.current)
      if (isBotTypingRef.current) {
        voiceTextRef.current = ""
        userMessagesRef.current = []
        return
      }
      console.log('recognized listening = ', isBotListeningRef.current)
      if (isBotListeningRef.current && e.result.reason === ResultReason.RecognizedSpeech) {
        //Display continuous transcript
        const cleaned_text = e.result.text.replace(/\.$/, ''); // removed puntuations
        console.log('recognized text = ', cleaned_text)
        if (cleaned_text === 'Play' || cleaned_text === 'BJP') return
        voiceTextRef.current += `${cleaned_text}`;
        speechDurationTimerRef.current += e.result.duration
        showMessage(cleaned_text)
        resetTimeout(SST_THRESHOLD);
      }
      else if (isBotListeningRef.current && e.result.reason === ResultReason.TranslatedSpeech) {
        //Display continuous transcript
        const cleaned_text = e.result.translations.get('en').replace(/\.$/, ''); // removed puntuations
        console.log('recognized text = ', cleaned_text)
        if (cleaned_text === 'Play' || cleaned_text === 'BJP') return
        voiceTextRef.current += `${cleaned_text}`;
        speechDurationTimerRef.current += e.result.duration
        showMessage(cleaned_text)
        resetTimeout(SST_THRESHOLD);
      }
    };

    recognizer.startContinuousRecognitionAsync();

    recognizerRef.current = recognizer
  };

  const onSubmit = (formValues: IChatActionBarFormValues) => {
    addNewMessage({
      id: uuidv4(),
      sender: 'user',
      data: {
        bb_type: 'output_text',
        bb_value: formValues.messageText.trim()
      },
      sentAt: new Date(),
    })
    sendMessage(formValues.messageText, 'text')
  }

  const ChatboxActionBar = (
    <React.Fragment>
      {!micOn ? (
        <button className="bb-btn" disabled={isSessionEnded} type='button' onClick={speak}>
          <svg style={{ width: '0.8em' }} fill='black' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z" /></svg>
        </button>
      ) : (
        <button className="bb-btn" disabled={isSessionEnded} type='button' onClick={mute}>
          <svg style={{ width: '0.8em' }} fill='black' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" /></svg>
        </button>
      )}
      <Controller
        defaultValue=""
        control={control}
        name="messageText"
        render={({
          field: { onChange, onBlur, value, name, ref },
        }) => (
          <FormTextInput
            name={name}
            className=""
            autoComplete='off'
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            type={'text'}
            disabled={isSessionEnded}
            placeholder="Ask your AI Assistant"
          />
        )}
      />

      <button disabled={isSessionEnded || isBotTypingRef.current} style={{ background: theme?.otherBackgroundColor, color: theme?.headerTextColor }} type='submit' className="bb-btn-send" id="send-btn">
        <svg style={{ width: '1.2em' }} fill={theme?.otherTextColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" /></svg>
      </button>
    </React.Fragment>
  )

  const PublicChatActionBar = (
    <React.Fragment>
      <div className="text-area">
        <Controller
          defaultValue=""
          control={control}
          name="messageText"
          render={({
            field: { onChange, onBlur, value, name, ref },
          }) => (
            <FormTextInput
              name={name}
              disabled={isSessionEnded}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              inputRef={ref}
              type={'text'}
              autoComplete='off'
              // error={errors.messageText}
              placeholder="Ask BestBrain"
            />
          )}
        />
      </div>
      <div className="btn-part">
        {!micOn ? (
          <button disabled={isSessionEnded} style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} type='button' onClick={speak}><i className="fa-solid fa-microphone"></i></button>
        ) : (
          <button disabled={isSessionEnded} style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} type='button' onClick={mute}><i className="fa-solid fa-stop"></i></button>
        )}
        <button style={{ backgroundColor: theme.otherBackgroundColor, color: theme.otherTextColor }} disabled={isSessionEnded || !isValid} type='submit'><i className="fa-regular fa-paper-plane"></i></button>
      </div>
    </React.Fragment>
  )

  return (
    <form
      className={layout === 'public' ? "text-area-part" : "bb-chat-input"}
      onSubmit={handleSubmit(onSubmit)}
      style={{ borderColor: theme.otherBackgroundColor }}
    >
      {layout === 'public' ? PublicChatActionBar : ChatboxActionBar}
    </form>
  )
}

export default ActionBar