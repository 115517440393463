import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { IChatContext, IChatMessageUpdate, IMessage } from '../interfaces/chat.interface';
import { v4 as uuidv4 } from 'uuid'

export const ChatContext = React.createContext<IChatContext | null>(null)

export const ChatProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [sessionId, setSessionId] = useState<string | null>(null)
  const sessionIdRef = useRef<string | null>(sessionId)
  sessionIdRef.current = sessionId
  const [threadId, setThreadId] = useState<string | null>(null)
  const threadIdRef = useRef<string | null>(threadId)
  threadIdRef.current = threadId
  const messagesRef = useRef<IMessage[]>([])  
  const [isSessionEnded, setIsSessionEnded] = useState<boolean>(false)
  const sessionEndedRef = useRef<boolean>(isSessionEnded)
  sessionEndedRef.current = isSessionEnded
  const [audioPlayerPlaying, setAudioPlayerPlaying] = useState<boolean>(false)
  const audioPlayerPlayingRef = useRef<boolean>(audioPlayerPlaying)
  audioPlayerPlayingRef.current = audioPlayerPlaying
  const [phraseHints, setPhraseHints] = useState<any>(null)
  const phraseHintsRef = useRef<any>(phraseHints)
  phraseHintsRef.current = phraseHints
  const [isBotListening, setIsBotListening] = useState<boolean>(false)
  const isBotListeningRef = useRef<boolean>(isBotListening)
  isBotListeningRef.current = isBotListening
  const isBotTypingRef = useRef<boolean>(false)
  const [forceRender, setForceRender] = useState(0);
  

  const setIsTyping = (isTyping: boolean) => {
    isBotTypingRef.current = isTyping
    const newMessages: IMessage[] = [...messagesRef.current].filter(
      (m: IMessage) => (m.data.bb_type !== 'typing')
    )
    if (isTyping) {
      newMessages.push({
        id: uuidv4(),
        sender: 'bot',
        data: {
          bb_type: 'typing'
        },
        sentAt: new Date(),
      })
    }
    messagesRef.current = newMessages
  }

  const addNewMessage = (message: IMessage) => {
    messagesRef.current = [...messagesRef.current, message]
    setForceRender((prev) => prev + 1); // Force re-render
    // console.log('new message added === ', messagesRef.current)
  }

  const updateMessage = (payload: IChatMessageUpdate) => {
    const oldMessageIndex = messagesRef.current.findIndex(
      (el) => (el.id === payload.messageId)
    )
    if (oldMessageIndex > -1) {
      messagesRef.current[oldMessageIndex] = {
        ...messagesRef.current[oldMessageIndex],
        data: {
          ...messagesRef.current[oldMessageIndex].data,
          ...payload.data
        }
      }
    }
  }

  const setChatHistory = (messages: IMessage[]) => {
    messagesRef.current = messages
  }

  const clearChat = () => {
    messagesRef.current = []
  }

  const manager = useMemo(() => ({
    sessionId,
    threadId,
    messages: messagesRef.current,
    isSessionEnded,
    audioPlayerPlaying,
    phraseHints,
    isBotListening,
    isBotTyping: isBotTypingRef.current,
    setSessionId,
    setThreadId,
    addNewMessage,
    setIsSessionEnded,
    setIsTyping,
    updateMessage,
    clearChat,
    setChatHistory,
    setAudioPlayerPlaying,
    setPhraseHints,
    setIsBotListening,
  }), [
    sessionId,
    threadId,
    messagesRef.current,
    isSessionEnded,
    audioPlayerPlaying,
    phraseHints,
    isBotTypingRef.current,
    isBotListening,
    isBotListeningRef.current
    // forceRender
  ])

  return (
    <ChatContext.Provider value={manager}>
      {children}
    </ChatContext.Provider>
  );
};
